import React from "react"

import SettingsIcon from "./assets/SettingsIcon"
import OptimizationIcon from "./assets/OptimizationIcon"
import SupportIcon from "./assets/SupportIcon"
import AnaliticsIcon from "./assets/AnalyticsIcon"

export default {
	title: "Зарегистрироваться",
	description:
		"Зарегистрируйтесь за пару минут и получите две недели обслуживания бесплатно.",
	advantages: [
		{
			id: 1,
			icon: <SettingsIcon />,
			text: "Помощь в настройке",
		},
		{
			id: 2,
			icon: <OptimizationIcon />,
			text: "Оптимизация бизнеса",
		},
		{
			id: 3,
			icon: <AnaliticsIcon />,
			text: "Аналитика в сматрфоне",
		},
		{
			id: 4,
			icon: <SupportIcon />,
			text: "Техническая поддержка 24/7",
		},
	],
}
