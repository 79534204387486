import React, { SVGProps } from "react"

const SupportIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.75 15.9998C2.75 13.7202 3.32566 11.5751 4.33953 9.70191C4.62462 9.17519 5.3319 9.10402 5.7554 9.52752L9.94519 13.7173C10.3148 14.0869 10.3148 14.6862 9.94519 15.0558L8.03812 16.9628C7.80483 17.1961 7.70644 17.5353 7.80207 17.8511C8.72193 20.8883 11.1274 23.28 14.1729 24.1807C14.4873 24.2737 14.8238 24.1749 15.0557 23.9431L16.9441 22.0547C17.3137 21.6851 17.9129 21.6851 18.2825 22.0547L22.4723 26.2444C22.8958 26.6679 22.8246 27.3752 22.2979 27.6603C20.4247 28.6741 18.2796 29.2498 16 29.2498C15.2855 29.2498 14.5841 29.1933 13.9002 29.0844C9.34478 28.359 5.56331 25.3119 3.80688 21.1941C3.12654 19.5991 2.75 17.8434 2.75 15.9998Z"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M7.63477 5.72423C9.91592 3.86489 12.8279 2.75 16.0003 2.75C23.3181 2.75 29.2503 8.68223 29.2503 16C29.2503 19.1802 28.1299 22.0986 26.2624 24.3823"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M16 15.9999V8.42847"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.9999 15.9998L23.5713 15.9998"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default SupportIcon
