import React, { SVGProps } from "react"

const AnaliticsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7 15.3491L7 4C7 3.44772 7.44772 3 8 3L24 3C24.5523 3 25 3.44772 25 4L25 5.45774M25 20.6409L25 28C25 28.5523 24.5523 29 24 29L8 29C7.44771 29 7 28.5523 7 28L7 26.5916"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M25.0001 15.6519V10.3776L19.7256 10.3774M23.8701 11.5075L17.4513 17.9263L14.0772 14.5523L7 21.6224"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default AnaliticsIcon
