import React from "react"
import classNames from "classnames"

import data from "./data"

import styles from "./styles.module.scss"

function RegistrationFacts(props) {
	return (
		<div className={classNames(styles.registrationAdvantages, props.className)}>
			<h1 className={styles.registrationAdvantages__title}>{data.title}</h1>
			<p className={styles.registrationAdvantages__description}>
				{data.description}
			</p>

			<ul className={styles.registrationAdvantages__list}>
				{data.advantages.map(advantage => (
					<li
						key={advantage.id}
						className={styles.registrationAdvantages__item}
					>
						{advantage.icon}
						{advantage.text}
					</li>
				))}
			</ul>
		</div>
	)
}

export default RegistrationFacts
