import React, { SVGProps } from "react"

const OptimizationIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M26.6699 8.57143C24.3207 5.20353 20.4177 3 16 3C8.8203 3 3 8.8203 3 16C3 17.0818 3.13213 18.1327 3.38114 19.1375M28.6127 12.8376C28.8657 13.8499 29 14.9093 29 16C29 23.1797 23.1797 29 16 29C11.5823 29 7.67932 26.7965 5.33014 23.4286"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M26.7441 8.57146V4.85718"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.31348 27.1428V23.4285"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.6139 8.75195C11.8005 9.13197 11.0535 9.64775 10.4065 10.2805C9.39772 11.2671 8.66782 12.5028 8.29083 13.8625C7.91384 15.2222 7.90317 16.6574 8.2599 18.0226C8.61663 19.3877 9.32807 20.6342 10.322 21.6357C11.316 22.6371 12.5572 23.3578 13.9196 23.7248C15.2821 24.0917 16.7173 24.0917 18.0798 23.7249C19.4423 23.3581 20.6835 22.6375 21.6775 21.6361C22.3366 20.9722 22.8714 20.2006 23.2611 19.3581"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M16 8V7C15.4477 7 15 7.44772 15 8H16ZM24 16V17C24.5523 17 25 16.5523 25 16H24ZM16 16H15C15 16.5523 15.4477 17 16 17V16ZM16 9C19.866 9 23 12.134 23 16H25C25 11.0294 20.9706 7 16 7V9ZM17 16V8H15V16H17ZM24 15H16V17H24V15Z"
			fill="#3021E5"
		/>
	</svg>
)

export default OptimizationIcon
