import React, { SVGProps } from "react"

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle
			cx="25"
			cy="21.1829"
			r="3"
			transform="rotate(90 25 21.1829)"
			stroke="#3021E5"
			strokeWidth="2"
		/>
		<path
			d="M25 3L25 17.3M25 29L25 25.1"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="7"
			cy="21.1829"
			r="3"
			transform="rotate(90 7 21.1829)"
			stroke="#3021E5"
			strokeWidth="2"
		/>
		<path
			d="M7 3L7 17.3M7 29L7 25.1"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			r="3"
			transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 16 10.8171)"
			stroke="#3021E5"
			strokeWidth="2"
		/>
		<path
			d="M16 29L16 14.7M16 3L16 6.9"
			stroke="#3021E5"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default SettingsIcon
